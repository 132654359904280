<template>
  <div
    class="lightspeed-announcement-list-item"
    :class="{ unread: isUnread }"
    @click.prevent="navigateToAnnouncement"
  >
    <div class="left">
      <announcement-thumbnail
        size="large"
        :announcement-id="announcement.id"
        :tenant-id="tenantId"
        :created-by="announcement.createdBy"
        :owner-id="announcement.ownerId"
      ></announcement-thumbnail>
    </div>
    <div class="right" :key="timeAgoRefreshKey">
      <div class="name">
        {{ displayName }}
      </div>

      <announcement-tags :announcement="announcement"></announcement-tags>

      {{ getLastActivityHuman(announcement) }}
    </div>
    <div class="super-right" v-if="!hidePin">
      <pin-button :guid="announcement.id"></pin-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import organisations from "../../../helpers/organisations";
import Dates from "./../../../mixins/Dates";
import TimeAgoRefresher from "./../../../mixins/TimeAgoRefresher";
import AnnouncementThumbnail from "../../../components/Announcements/AnnouncementThumbnail.vue";
import AnnouncementTags from "../../../components/Announcements/AnnouncementTags.vue";
import PinButton from "../../../components/PinButton.vue";
const _ = require("lodash");
export default {
  components: { AnnouncementThumbnail, AnnouncementTags, PinButton },
  props: ["announcement", "hidePin"],
  mixins: [Dates, TimeAgoRefresher],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
    }),

    ...mapGetters("announcements", {
      searchQuery: "searchQuery",
      unreadAnnouncements: "unreadAnnouncements",
    }),

    tenantId() {
      let organisation = this.getOrganisationById(this.announcement.ownerId);
      return organisations.getOrganisationTenantId(organisation);
    },

    displayName() {
      if (
        this.announcement.displayName &&
        this.announcement.displayName.length
      ) {
        return this.announcement.displayName;
      }
      return "Unknown";
    },

    isUnread() {
      return _.filter(this.unreadAnnouncements, { id: this.announcement.id })
        .length;
    },
  },
  methods: {
    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    getAnnouncementUrl(announcement) {
      return "/lightspeed/announcements/" + this.tenantId + "/" + announcement.id;
    },

    navigateToAnnouncement() {
      this.$router.push(this.getAnnouncementUrl(this.announcement));
    },
  },
};
</script>

<style scoped lang="scss">
.lightspeed-announcement-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

   .super-right {
    padding-left: 5px;
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  .name {
    font-weight: 500;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  &.unread {
    .name {
      font-weight: 700 !important;
      color: #000;
    }
    font-weight: 500;
    color: #000;
  }
}
</style>